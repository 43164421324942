@import '../scss/_mixins.global.scss';

.product-card-new-slide {
  transition: 0.25s all ease-in-out;
}

.product-card-new-slide {
  @include xs {
    zoom: 49.5%;
    // transform: scale(0.495);
  }

  @include xsp {
    zoom: 55.5%;
    // transform: scale(0.495);
  }

  @include sm {
    zoom: 100% !important;
    // transform: scale(1) !important;
  }
}
