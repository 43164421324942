// This query covers devices like the iPhone X, iPhone 11, iPhone 12, etc., with a minimum width of 375px and a maximum width of 812px, along with a pixel ratio of 3
@media screen and (max-device-width: 480px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  button {
    padding: 0; /* Ensure padding is fully reset on iPhones */
  }

  .home-footer {
    -webkit-text-size-adjust: 77%;
  }

  .index-page {
    .customer-reviews-carousel {
      .swiper {
        -webkit-text-size-adjust: 67%;
      }
    }
  }
}
