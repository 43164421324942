@import 'breakpoints.global';

// Mixin for extra small (xs) devices
@mixin xs {
  @media screen and (min-width: $xs-breakpoint) {
    @content;
  }
}

// Mixin for extra small plus (xsp) devices
@mixin xsp {
  @media screen and (min-width: $xsp-breakpoint) {
    @content;
  }
}

// Mixin for small (sm) devices
@mixin sm {
  @media screen and (min-width: $sm-breakpoint) {
    @content;
  }
}

// Mixin for medium (md) devices
@mixin md {
  @media screen and (min-width: $md-breakpoint) {
    @content;
  }
}

// Mixin for medium plus (mdp) devices
@mixin mdp {
  @media screen and (min-width: $mdp-breakpoint) {
    @content;
  }
}

// Mixin for large (lg) devices
@mixin lg {
  @media screen and (min-width: $lg-breakpoint) {
    @content;
  }
}

// Mixin for large plus (lgp) devices
@mixin lgp {
  @media screen and (min-width: $lgp-breakpoint) {
    @content;
  }
}

// Mixin for extra large (xl) devices
@mixin xl {
  @media screen and (min-width: $xl-breakpoint) {
    @content;
  }
}

// Mixin for extra large plus (xlp) devices
@mixin xlp {
  @media screen and (min-width: $xlp-breakpoint) {
    @content;
  }
}

// Mixin for extra large 1 (xl1) devices
@mixin xl1 {
  @media screen and (min-width: $xl1-breakpoint) {
    @content;
  }
}

// Mixin for extra large 2 (xl2) devices
@mixin xl2 {
  @media screen and (min-width: $xl2-breakpoint) {
    @content;
  }
}

// Mixin for extra large 3 (xl3) devices
@mixin xl3 {
  @media screen and (min-width: $xl3-breakpoint) {
    @content;
  }
}

// Mixin for extra large 4 (xl4) devices
@mixin xl4 {
  @media screen and (min-width: $xl4-breakpoint) {
    @content;
  }
}

@mixin font-size($sizeValue: 12) {
  font-size: ($sizeValue / 16) + rem;
}

@mixin container($containerSize: $sm-breakpoint) {
  @container (min-width: $containerSize) {
    @content;
  }
}
